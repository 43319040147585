import React, { useState } from 'react';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { ToastContainer, toast } from 'react-toastify';
import icevan from "../../images/Desktop-icons/ice-cream-van.svg";
import Navbardesktop from '../../components/Web/Navbar/Navbardesktop,';


const GuestLogin = () => {
  const [progress, setProgress] = useState(0);

  const validationSchema = Yup.object({
    name: Yup.string()
      .required('Name is required.')
      .min(2, 'Name must be at least 2 characters long.'),
    email: Yup.string()
      .email('Please enter a valid email address.')
      .required('Email is required.'),
    mobile: Yup.string()
      .matches(
        /^\+?[1-9]\d{1,14}$/,
        'Mobile number must be a valid international number.'
      )
      .required('Mobile number is required.'),
  });

  const handleSubmit = async (values, { setSubmitting }) => {
    try {
      console.log('Form submitted with values:', values);
      localStorage.setItem('guestUser', JSON.stringify(values));
      toast.success('Login successful!', { className: 'custom-toast' });
    } catch (error) {
      toast.error('An unexpected error occurred', { className: 'custom-toast' });
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <div>
      <ToastContainer />
      <Navbardesktop/>
      <div className="sign-main-head"></div>
      <div className="sign-main-body">
        <div className="sign-main-steps">
          <div className="sign-main-step sign-main-step-1">1</div>
          <div className={`sign-main-line progress-${progress}`}></div>
          <div className="sign-main-step sign-main-step-2">2</div>
          <img
            src={icevan}
            alt="Ice Cream Van"
            className={`sign-main-ice-van progress-${progress}`}
          />
        </div>
      </div>
      
      <div className="sign-main-login-section">
        <Formik
          initialValues={{
            name: '',
            email: '',
            mobile: '',
          }}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {({ isSubmitting, values }) => {
            // Calculate progress based on filled fields
            const filledFields = Object.values(values).filter(Boolean).length;
            const newProgress = (filledFields / 3) * 100;
            if (newProgress !== progress) {
              setProgress(newProgress);
            }
            
            return (
                
              <Form className="sign-main-login-section">
                <h2 className="sign-main-login-section-head">Guest Login</h2>
                <div className="sign-main-fields-container">
                  <div className="sign-main-field">
                    <p className="sign-main-login-section-p">Full Name</p>
                    <Field
                      type="text"
                      name="name"
                      placeholder="Enter your full name"
                      className="sign-main-input"
                    />
                    <ErrorMessage name="name" component="p" className="sign-main-validation-message" />
                  </div>

                  <div className="sign-main-field">
                    <p className="sign-main-login-section-p">Email ID</p>
                    <Field
                      type="email"
                      name="email"
                      placeholder="Enter your Email"
                      className="sign-main-input"
                    />
                    <ErrorMessage name="email" component="p" className="sign-main-validation-message" />
                  </div>

                  <div className="sign-main-field">
                    <p className="sign-main-login-section-p">Mobile Number</p>
                    <Field
                      type="text"
                      name="mobile"
                      placeholder="Enter your Mobile number"
                      className="sign-main-input"
                    />
                    <ErrorMessage name="mobile" component="p" className="sign-main-validation-message" />
                  </div>
                </div>

                <div className="sign-main-fields-buttons">
                  <button
                    className="main-signup-button"
                    type="submit"
                    disabled={isSubmitting}
                  >
                    Continue as Guest
                  </button>
                  <p className="sign-main-fields-p">
                    Already have an account? <a href="/login">Login</a>
                  </p>
                </div>
              </Form>
            );
          }}
        </Formik>
      </div>
    </div>
  );
};

export default GuestLogin;