import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import "./LoginPage.css";
import userIcon from "../../images/Desktop-icons/icecream-login-icon.svg";
import helpcenterlogo from "../../images/Desktop-icons/help-center.svg";
import { isMobile } from "react-device-detect";
import MobLoginPage from "../../components/Mob/MobLogin/MobLoginPage";
import Navbardesktop from "../../components/Web/Navbar/Navbardesktop,";
import axios from "axios";
import { toast, ToastContainer } from "react-toastify";

const baseUrl = process.env.REACT_APP_BASEURL;

const LoginPage = () => {
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [showToast, setShowToast] = useState(false);
  const navigate = useNavigate();

  const validationSchema = Yup.object({
    email: Yup.string().email("Invalid email format").required("Email is required"),
    password: Yup.string().min(6, "Password must be at least 6 characters").required("Password is required"),
  });

  useEffect(() => {
    if (isPopupOpen) {
      document.body.classList.add("no-scroll");
    } else {
      document.body.classList.remove("no-scroll");
    }
    return () => document.body.classList.remove("no-scroll");
  }, [isPopupOpen]);

  const openPopup = () => {
    setIsPopupOpen(true);
    setShowToast(true);
    setTimeout(() => {
      setShowToast(false);
    }, 4000);
  };

  const closePopup = () => {
    setIsPopupOpen(false);
  };

  const handleSignupClick = () => {
    navigate("/signup");
  };

  const handleSubmit = async (values, { setSubmitting }) => {
    try {
      const response = await axios.post(`${baseUrl}/api/user/login`, values);
      const userData = response.data;
      localStorage.setItem("userData", JSON.stringify(userData));
      toast.success(response.data.message, { className: "custom-toast" });
      navigate('/');
    } catch (error) {
      const errorMessage = error.response?.data?.message || "An unexpected error occurred";
      toast.error(errorMessage, { className: "custom-toast" });
    } finally {
      setSubmitting(false);
    }
  };

  return isMobile ? (
    <MobLoginPage />
  ) : (
    <div>
      <ToastContainer />
      {showToast && <div className="toast-message">Welcome Back</div>}
      <div className="navbar-shadow">
        <Navbardesktop />
      </div>
      <div className="login-page-container">
        <div className="login-content">
          <img src={userIcon} alt="User Icon" className="user-icon" />
          <p className="welcome-text">WELCOME TO MANNA DELIGHTS</p>
          <div className="login-buttons-main">
            <button className="login-signup-button" onClick={openPopup}>
              Login
            </button>
            
            <button className="login-signup-button" onClick={handleSignupClick}>
              Signup
            </button>
            <button
    className="login-signup-button"
    onClick={() => {
      navigate('/guest-home');
    }}
  >
   Guest login
  </button>
          </div>
        </div>
      </div>
      <div className="help-center-section">
        <img
          src={helpcenterlogo}
          alt="Help Center Icon"
          className="help-center-icon"
        />
        <a href="/help-center" className="help-center-link">
          Help Center
        </a>
      </div>

      {isPopupOpen && (
        <div className="login-popup-container">
          <div className="login-popup-box">
            <button className="close-button" onClick={closePopup}>
              close
            </button>
            <div className="login-header">
              <h1>WELCOME</h1>
            </div>
            <Formik
              initialValues={{ email: "", password: "" }}
              validationSchema={validationSchema}
              onSubmit={handleSubmit}
            >
              {({ isSubmitting }) => (
                <Form className="login-form">
                  <div className="login-field">
                    <label htmlFor="email">Email Address</label>
                    <Field type="email" name="email" placeholder="Enter Email" />
                    <ErrorMessage name="email" component="div" className="error-message" />
                  </div>
                  <div className="login-field">
                    <label htmlFor="password">Password</label>
                    <Field type="password" name="password" placeholder="Enter Password" />
                    <ErrorMessage name="password" component="div" className="error-message" />
                  </div>
                  <div className="login-actions">
                    <button type="submit" className="login-button" disabled={isSubmitting}>
                      {isSubmitting ? "Logging In..." : "Log In"}
                    </button>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </div>
      )}
    </div>
  );
};

export default LoginPage;
