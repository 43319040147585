import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom"; // Import useNavigate hook
import "./MobFooter.css";

import sideborder from "../../../images/Mask Group 10.svg";
import downborder from "../../../images/Mask Group 8.svg";
import leftborder from "../../../images/Mask Group 11.svg";
import leftsideborder from "../../../images/Mask Group 9.svg";
import facebookicon from "../../../images/Group 70.svg";
import instaicon from "../../../images/Group 69.svg";
import linkicon from "../../../images/Group 68.svg";
import footmainlogo from "../../../images/bottom-logo.svg";
import axios from "axios";
const baseUrl = process.env.REACT_APP_BASEURL;

const MobFooter = () => {
  const navigate = useNavigate(); 
  const [dtails, setdetails] = useState([]);

  const [social, setsocial] = useState([]);
  const fetchalloncontactdetail = async () => {
    try {
      const response = await axios.get(`${baseUrl}/api/homepage/allon/contactdetail`);
      console.log(response.data, "social");
      setdetails(response.data);
    } catch (error) {
      console.error("Network error:", error);
    }
  };
  const fetchsocialdatas = async () => {
    try {
      const response = await axios.get(`${baseUrl}/api/socialmedia/allon`);
      console.log(response.data, "social");
      setsocial(response.data);
    } catch (error) {
      console.error("Network error:", error);
    }
  };
  useEffect(() => {
    fetchsocialdatas();
    fetchalloncontactdetail();
  }, []);
  const handleNavigation = (path) => {
    navigate(path);
  };

  return (
    <div className="Mob-mainfooter">
      <img src={sideborder} alt="Side Border" className="Mob-sideborder" />
      <img src={downborder} alt="Down Border" className="Mob-downborder" />
      <img src={leftborder} alt="Left Border" className="Mob-leftborder" />
      <img
        src={leftsideborder}
        alt="Left Side Border"
        className="Mob-leftsideborder"
      />

      <div className="Mob-footer-center-section">
        <img src={footmainlogo} alt="Main Logo" className="Mob-footmainlogo" />
        <p className="Mob-footer-center-contacttext">CONTACT US</p>
        <p className="Mob-footer-center-contactnumber">{dtails[0]?.number}</p>
        <p className="Mob-footer-centersec-contactnumber">{dtails[0]?.contactnumber}</p>
        <p className="Mob-footer-center-contactcontent">
        {dtails[0]?.email}        </p>
      </div>

      <div className="Mob-footer-middle-section">
        <div className="Mob-footer-left-section">
          <p className="Mob-footer-left-sectionp">
          {dtails[0]?.address}          </p>
          <div className="Mob-footer-social-icons">
            <img
              src={facebookicon}
              alt="Facebook"
              className="Mob-social-foot-icon"
              onClick={() => (window.location.href = social[0]?.facebook)}
            />
            <img
              src={instaicon}
              alt="Instagram"
              onClick={() => (window.location.href = social[0]?.instagram)}
              className="Mob-social-foot-icon"
            />
            <img
              src={linkicon}
              alt="Link"
              className="Mob-social-foot-icon"
              onClick={() => (window.location.href = social[0]?.linkedlin)}
            />
          </div>
        </div>

        <div className="Mob-footer-right-section">
          <h4 className="Mob-footer-right-sectionhead">QUICK LINKS</h4>
          <p
            className="Mob-footer-right-sectionp"
            onClick={() => handleNavigation("/career")}
          >
            Careers
          </p>
          <p
            className="Mob-footer-right-sectionp"
            onClick={() => handleNavigation("/terms")}
          >
            Terms
          </p>
          <p
            className="Mob-footer-right-sectionp"
            onClick={() => handleNavigation("/policy")}
          >
            Policy
          </p>
          <p
            className="Mob-footer-right-sectionp"
            onClick={() => handleNavigation("/training")}
          >
            Training
          </p>
        </div>

        <div className="Mob-footer-right-section">
          <h4 className="Mob-footer-right-sectionhead">ENQUIRIES</h4>
          <p
            className="Mob-footer-right-sectionp"
            onClick={() => handleNavigation("/dealership")}
          >
            Dealership
          </p>
          <p
            className="Mob-footer-right-sectionp"
            onClick={() => handleNavigation("/influencer")}
          >
            Influencer
          </p>
          <p
            className="Mob-footer-right-sectionp"
            onClick={() => handleNavigation("/bulk-ordering")}
          >
            Bulk ordering
          </p>
         
        </div>
      </div>

      <div className="Mob-footer-copyright">
        <hr />
        <p>MANNA DELIGHT - © 2024 ALL RIGHTS RESERVED</p>
      </div>
    </div>
  );
};

export default MobFooter;
