import React, { useEffect, useState } from "react";
import "./MobileBlogPage.css";
import backbutton from "../../../images/Mobile-icons/Backbutton.svg";
import settingmob from "../../../images/Mobile-icons/settings-svgrepo.svg";
import menu from "../../../images/Desktop-icons/menu-svg.svg";
import aboutus from "../../../images/Desktop-icons/about-svgrepo-com.svg";
import blogs from "../../../images/Desktop-icons/blog.svg";
import contactus from "../../../images/Desktop-icons/contact-svg.svg";
import axios from "axios";
import { toast } from "react-toastify";
import { useNavigate } from 'react-router-dom';

const baseUrl = process.env.REACT_APP_BASEURL;

const MobileBlogPage = () => {
  const [selectedTab, setSelectedTab] = useState("newest");
  const [isPopupOpen, setPopupOpen] = useState(false);
  const [blogs, setBlogs] = useState([]);
  const [trendingblogs, setTrendingBlogs] = useState([]);
  const navigate = useNavigate();

  const fetchBlogs = async () => {
    try {
      const response = await axios.get(`${baseUrl}/api/blog/allonblogs`);
      console.log(response.data);
      if (Array.isArray(response.data)) {
        setBlogs(response.data);
      } else {
        console.error("API response is not an array:", response.data);
        toast.error("Error fetching blogs");
      }
    } catch (error) {
      console.error("Error fetching blogs:", error);
      toast.error("Error fetching blogs");
    }
  };

  const fetchTrendingBlogs = async () => {
    try {
      const response = await axios.get(`${baseUrl}/api/blog/allongallery`);
      console.log(response.data);
      if (Array.isArray(response.data)) {
        setTrendingBlogs(response.data);
      } else {
        console.error("API response is not an array:", response.data);
        toast.error("Error fetching trending blogs");
      }
    } catch (error) {
      console.error("Error fetching trending blogs:", error);
      toast.error("Error fetching trending blogs");
    }
  };

  useEffect(() => {
    fetchBlogs();
    fetchTrendingBlogs();
  }, []);

  const handleTabClick = (tab) => {
    setSelectedTab(tab);
  };

  const togglePopup = () => {
    setPopupOpen(!isPopupOpen);
  };

  return (
    <div className="mob-blog-page">
      <div className="mob-blog-navbar">
        <img src={backbutton} alt="Back" className="mob-blog-back-button" onClick={() => navigate(-1)}/>
        <div className="mob-blog-title">BLOG</div>
        <img src={settingmob} alt="Menu" className="mob-blog-menu-icon" onClick={togglePopup} />
      </div>

      <PopupMenu isOpen={isPopupOpen} onClose={togglePopup} />

      <div className="mob-blog-content">
        <div className="mob-blog-section">
          <div className="mob-blog-nav">
            <ul>
              <li
                className={selectedTab === "newest" ? "mob-blog-selected" : ""}
                onClick={() => handleTabClick("newest")}
              >
                Newest
              </li>
              <li
                className={selectedTab === "trending" ? "mob-blog-selected" : ""}
                onClick={() => handleTabClick("trending")}
              >
                Trending
              </li>
            </ul>
          </div>
        </div>

        <div className="mob-blog-posts">
          {selectedTab === "newest" &&
            blogs?.map((item, index) => (
              <div className="mob-blog-post" key={index}>
                <div>
                  <h2 className="mob-blog-post-title">{item?.name}</h2>
                <img src={item?.image} alt="Blog Post" />
                  <p className="mob-blog-post-content">{item?.description}</p>
                </div>
              </div>
            ))}
  <div className="mob-blog-posts-trends">
          {selectedTab === "trending" &&
            trendingblogs?.map((item, index) => (
              <div className="mob-blog-post-trend" key={index}>
                <div  >
                  {/* <h2 className="mob-blog-post-title">{item?.name}</h2> */}
                <img src={item?.image} alt="Blog Post" className="mob-blog-post-gallery"/>
                  {/* <p className="mob-blog-post-content">{item?.description}</p> */}
                </div>
              </div>
              
            ))}
        </div>
        </div>
      </div>
    </div>
  );
};

const PopupMenu = ({ isOpen, onClose }) => {
  const navigate = useNavigate(); 

  const handleNavigate = (path) => {
    navigate(path); 
    onClose();       
  };

  return (
    <div className={`mob-training-popup-menu ${isOpen ? "show" : ""}`}>
      <ul className="mob-training-pop-menu-navbar-main">
        <li onClick={() => handleNavigate("/")}>
          Home
          <img src={menu} className="mob-training-pop-menu-navbar-img" alt="Menu" />
        </li>
        <li onClick={() => handleNavigate("/about")}>
          About Us
          <img src={aboutus} className="mob-training-pop-menu-navbar-img" alt="About Us" />
        </li>
        <li onClick={() => handleNavigate("/blog")}>
          Blogs
          <img src={blogs} className="mob-training-pop-menu-navbar-img" alt="Blogs" />
        </li>
        <li onClick={() => handleNavigate("/contact")}>
          Contact Us
          <img src={contactus} className="mob-training-pop-menu-navbar-img" alt="Contact Us" />
        </li>
      </ul>
      <button onClick={onClose} className="mob-training-close-popup">
        Close
      </button>
    </div>
  );
};


export default MobileBlogPage;
