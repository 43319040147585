import React, { useEffect, useState } from "react";
import "./Footer.css";
import sideborder from "../../../images/Mask Group 10.svg";
import downborder from "../../../images/Mask Group 8.svg";
import leftborder from "../../../images/Mask Group 11.svg";
import leftsideborder from "../../../images/Mask Group 9.svg";
import facebookicon from "../../../images/Group 70.svg";
import instaicon from "../../../images/Group 69.svg";
import linkicon from "../../../images/Group 68.svg";
import footmainlogo from "../../../images/bottom-logo.svg";
import { isMobile } from "react-device-detect";
import MobFooter from "../../Mob/MobFooter/MobFooter";
import { useNavigate } from "react-router-dom";
import axios from "axios";
const baseUrl = process.env.REACT_APP_BASEURL;

const Footer = () => {
  const navigate = useNavigate();
  const [social, setsocial] = useState([]);
  const [dtails, setdetails] = useState([]);

  const fetchsocialdatas = async () => {
    try {
      const response = await axios.get(`${baseUrl}/api/socialmedia/allon`);
      console.log(response.data, "social");
      setsocial(response.data);
    } catch (error) {
      console.error("Network error:", error);
    }
  };
  const fetchalloncontactdetail = async () => {
    try {
      const response = await axios.get(`${baseUrl}/api/homepage/allon/contactdetail`);
      console.log(response.data, "social");
      setdetails(response.data);
    } catch (error) {
      console.error("Network error:", error);
    }
  };
  useEffect(()=>{
    fetchsocialdatas();
    fetchalloncontactdetail();
  },[])
  return isMobile ? (
    <MobFooter/>
  ):(
    <div>
      <div className="mainfooter">
        <img src={sideborder} alt="Side Border" className="sideborder" />
        <img src={downborder} alt="down Border" className="downborder" />
        <img src={leftborder} alt="Side Border" className="leftborder" />
        <img
          src={leftsideborder}
          alt="Side Border"
          className="leftsideborder"
        />
        <div className="footersub-sectionmain">
          <div className="footer-left-section">
            <p className="footer-left-sectionp">
              {dtails[0]?.address}
            </p>
            {/* <p className='footer-left-sectionp'>Newlands</p>
          <p className='footer-left-sectionp'>N3 2AX</p> */}
            <div className="footer-social-icons">
              <img
                src={facebookicon}
                alt="Side Border"
                className="social-foot-icon"
                onClick={() => window.location.href = social[0]?.facebook}

              />
              <img
                src={instaicon}
                alt="Side Border"
                onClick={() => window.location.href = social[0]?.instagram}
                className="social-foot-icon"
              />
              <img
                src={linkicon}
                alt="Side Border"
                className="social-foot-icon"
                onClick={() => window.location.href = social[0]?.linkedlin}

              />
            </div>
          </div>
          <div className="footer-center-section">
            <img
              src={footmainlogo}
              alt="Side Border"
              className="footmainlogo"
            />
            <br />
            <p className="footer-center-contacttext">CONTACT US</p>
            <p className="footer-center-contactnumber">{dtails[0]?.number}</p>
            <p className="footer-centersec-contactnumber">{dtails[0]?.contactnumber}</p>
            <p className="footer-center-contactcontent">
            {dtails[0]?.email}            </p>
          </div>

          <div className="footer-right-section">
  <div className="footer-links-section">
    <h4 className="footer-links-heading">ENQUIRIES</h4>
    <p className="footer-first-sectionp" onClick={() => navigate('/dealership')}>Dealership</p>
    <p className="footer-first-sectionp" onClick={() => navigate('/influencer')}>Influencer</p>
    <p className="footer-first-sectionp" onClick={() => navigate('/bulk-ordering')}>Bulk ordering</p>
  </div>

  <div className="footer-links-section">
    <h4 className="footer-links-heading">QUICK LINKS</h4>
    <p className="footer-right-sectionp" onClick={() => navigate('/career')}>Careers</p>
    <p className="footer-right-sectionp" onClick={() => navigate('/terms')}>Terms</p>
    <p className="footer-right-sectionp" onClick={() => navigate('/Policy')}>Policy</p>
    <p className="footer-right-sectionp" onClick={() => navigate('/training')}>Training</p>
  </div>
</div>

        </div>
        <br />
        <div className="footer-copyright">
          <hr />
          <p>MANNA DELIGHT - © 2024 ALL RIGHTS RESERVED</p>
        </div>
      </div>
    </div>
  );
};

export default Footer;
