import React, { useState } from "react";
import "./MobSignupPage.css";
import backIcon from "../../../images/Mobile-icons/Backbutton.svg";
import axios from "axios";
import { toast } from "react-toastify";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useNavigate } from "react-router-dom";
const baseUrl = process.env.REACT_APP_BASEURL;

const MobSignupPage = () => {
  const navigate = useNavigate();
  const [progress, setProgress] = useState(25);
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const handlePasswordVisibility = () => setShowPassword(!showPassword);
  const handleConfirmPasswordVisibility = () => setShowConfirmPassword(!showConfirmPassword);

  const validationSchema = Yup.object({
    email: Yup.string()
      .email("Enter a valid email address.")
      .required("Email is required."),
    mobile: Yup.string()
      .matches(/^\+?[1-9]\d{1,14}$/, "Mobile number must be a valid international number.")
      .required("Mobile number is required."),
    password: Yup.string()
      .required("Password is required.")
      .min(6, "Password must be at least 8 characters long.")
      .matches(/(?=.*[A-Z])/, "Password must contain at least one uppercase letter.")
      .matches(/(?=.*\d)/, "Password must contain at least one number.")
      .matches(/(?=.*[@$!%*?&])/, "Password must contain at least one special character."),
    confirmPassword: Yup.string()
      .oneOf([Yup.ref("password"), null], "Passwords must match.")
      .required("Confirm password is required."),
  });

  const formik = useFormik({
    initialValues: {
      email: "",
      mobile: "",
      password: "",
      confirmPassword: "",
    },
    validationSchema,
    onSubmit: async (values) => {
      try {
        const otpResponse = await axios.post(`${baseUrl}/api/otp/`, {
          email: values.email,
        });
        toast.success(otpResponse.data.message || "OTP sent successfully!", { className: "mob-toast" });

        const checkUserResponse = await axios.post(`${baseUrl}/api/user/checkuser`, {
          email: values.email,
          mobile: values.mobile,
        });
        toast.success(checkUserResponse.data.message || "User validated successfully!", { className: "mob-toast" });

        localStorage.setItem(
          "userData",
          JSON.stringify({
            email: values.email,
            mobile: values.mobile,
            password: values.password,
          })
        );
        toast.success("Signup successful!", { className: "mob-toast" });
        setProgress(50);

        navigate("otppage");
      } catch (error) {
        const errorMessage = error.response?.data?.message || "An unexpected error occurred";
        toast.error(errorMessage, { className: "mob-toast" });
      }
    },
  });

  return (
    <div>
      <div className="mob-signup-navbar">
        <img
          src={backIcon}
          alt="Back"
          className="mob-signup-back-icon"
          onClick={() => navigate(-1)}
        />
        <p className="mob-signup-title">Create Account</p>
      </div>
      <div className="mob-signup-main-head"></div>
      <div className="mob-signup-main-body">
        <div className="mob-signup-main-steps">
          <div
            className={`mob-signup-main-step mob-signup-main-step-1 ${
              progress >= 25 ? "progress-25" : ""
            }`}
          >
            1
          </div>
          <div
            className={`mob-signup-main-line ${
              progress >= 25 ? "progress-25" : ""
            } ${progress >= 50 ? "progress-50" : ""} ${
              progress >= 75 ? "progress-75" : ""
            } ${progress === 100 ? "progress-100" : ""}`}
          ></div>
          <div
            className={`mob-signup-main-step mob-signup-main-step-2 ${
              progress >= 50 ? "progress-50" : ""
            }`}
          >
            2
          </div>
          <div
            className={`mob-signup-main-step mob-signup-main-step-3 ${
              progress >= 75 ? "progress-75" : ""
            }`}
          >
            3
          </div>
        </div>
      </div>
      <div className="mob-signup-main-login-section">
        <h2 className="mob-signup-main-login-head">Sign Up to Manna Delights</h2>
        <div className="mob-signup-main-body">
<>
        <form onSubmit={formik.handleSubmit}>

        <div className="mob-signup-main-fields-container">
          <div className="mob-signup-main-field">
            <p>Email ID</p>
            <input
              type="email"
              name="email"
              placeholder="Enter your Email"
              onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.email}
                className={formik.touched.email && formik.errors.email ? "input-error" : ""}
            />
            {formik.touched.email && formik.errors.email && (
                <p className="mob-signup-main-validation-message">{formik.errors.email}</p>
              )}
          </div>
          <div className="mob-signup-main-field">
            <p>Mobile Number</p>
            <input
              type="text"
              name="mobile"
              placeholder="Enter your Mobile number*"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.mobile}
              className={formik.touched.mobile && formik.errors.mobile ? "input-error" : ""}
            />
             {formik.touched.mobile && formik.errors.mobile && (
                <p className="mob-signup-main-validation-message">{formik.errors.mobile}</p>
              )}
            <p className="mob-signup-main-otp-placeholder">
              OTP will be sent to this number
            </p>
          </div>
          <div className="mob-signup-main-field">
        <p>Password</p>
        <div className="input-container">
        <input
                  type={showPassword ? "text" : "password"}
                  name="password"
                  placeholder="Enter New Password"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.password}
                  className={formik.touched.password && formik.errors.password ? "input-error" : ""}
                />
          <span
            className="eye-icon"
            onClick={handlePasswordVisibility}
            style={{ cursor: "pointer" }}
          >
            👁️
          </span>
        </div>
        <div className="mob-signup-main-validation-message">
        {formik.touched.password && formik.errors.password && (
                <p className="mob-signup-main-validation-message">{formik.errors.password}</p>
              )}
        </div>
      </div>

      <div className="mob-signup-main-field">
        <p>Confirm Password</p>
        <div className="input-container">
        <input
                  type={showConfirmPassword ? "text" : "password"}
                  name="confirmPassword"
                  placeholder="Re-Enter Password"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.confirmPassword}
                  className={
                    formik.touched.confirmPassword && formik.errors.confirmPassword
                      ? "input-error"
                      : ""
                  }
                />
          <span
            className="eye-icon"
            onClick={handleConfirmPasswordVisibility}
            style={{ cursor: "pointer" }}
          >
            👁️
          </span>
        </div>
        {formik.touched.confirmPassword && formik.errors.confirmPassword && (
                <p className="mob-signup-main-validation-message">{formik.errors.confirmPassword}</p>
              )}
      </div>
        </div>
        <div className="mob-signup-main-fields-buttons">
          <button
             className={`mob-signup-main-button ${formik.isValid ? "active" : "inactive"}`}
             type="submit"
             disabled={!formik.isValid}
          >
            Sign Up
          </button>
          <p>
            Already have an account? <a href="#">Login</a>
          </p>
        </div>
        </form>
</>
        </div>

      </div>
    </div>
  );
};

export default MobSignupPage;
