import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import "./GuestCart.css";

const GuestCart = () => {
  const navigate = useNavigate();
  const [quantities, setQuantities] = useState({
    item1: 1,
    item2: 2,
  });
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [preparationInstructions, setPreparationInstructions] = useState("");

  const handleIncreaseQuantity = (itemId) => {
    setQuantities((prev) => ({
      ...prev,
      [itemId]: prev[itemId] + 1,
    }));
  };

  const handleDecreaseQuantity = (itemId) => {
    if (quantities[itemId] > 1) {
      setQuantities((prev) => ({
        ...prev,
        [itemId]: prev[itemId] - 1,
      }));
    }
  };

  const handleOpenPopup = () => setIsPopupOpen(true);
  const handleClosePopup = () => setIsPopupOpen(false);
  const handleSaveInstructions = () => {
    console.log("Instructions saved:", preparationInstructions);
    setIsPopupOpen(false);
  };
  const storedData = localStorage.getItem("guestInfo");
  const Data = JSON.parse(storedData);
  console.log(Data, "Data");

  return (
    <div className="guest-cart-full-width-container">
      <div className="guest-cart-navbar">
        <img
          src="/path-to-your-logo.svg"
          className="guest-cart-sidemainlogo"
          alt="Side Logo"
        />
        <img
          className="guest-cart-back-button"
          src="/path-to-back-button.svg"
          alt="Back Button"
          onClick={() => navigate(-1)}
        />
        <div className="guest-cart-page-title">Cart</div>
      </div>

      <div className="guest-cart-item-container">
        <div className="guest-cart-item-firstboxcontainer">
          <div className="guest-cart-secondbox">
            <div className="guest-cart-item-details">
              <img
                src="/path-to-whatsapp.svg"
                className="guest-cart-whatsappicon"
                alt="WhatsApp Icon"
              />
              <span className="guest-cart-whatsapp-text">
                (We'll send order updates on this number)
              </span>
            </div>

            <hr className="guest-cart-grey-line" />
            <p className="guest-cart-items-in-cart">Items in cart</p>
            {Data?.map((item, index) => (
              <>
                <div className="guest-cart-allitemsdiv">
                  <img
                    src="/path-to-product-image.jpg"
                    className="guest-cart-product-image"
                    alt="Ice Cream"
                  />
                  <div className="guest-cart-product-details">
                    <p className="guest-cart-product-details-name">
                      {item?.productname}
                    </p>
                    <p className="guest-cart-item-description">Small scoop</p>
                    <p className="guest-cart-item-price">₹100</p>
                  </div>

                  <div className="guest-cart-quantity-control">
                    <button
                      className="guest-cart-quantity-button"
                      onClick={() => handleDecreaseQuantity("item1")}
                    >
                      -
                    </button>
                    <p className="guest-cart-quantity-display">
                      {quantities.item1}
                    </p>
                    <button
                      className="guest-cart-quantity-button"
                      onClick={() => handleIncreaseQuantity("item1")}
                    >
                      +
                    </button>
                  </div>
                </div>
                <div className="guest-cart-preparation-instructions">
                  <button className="guest-cart-delete-button">Remove</button>
                  <button
                    className="guest-cart-preparation-button"
                    onClick={handleOpenPopup}
                  >
                    Add Preparation Instructions +
                  </button>
                </div>
              </>
            ))}
          </div>
        </div>

        <div className="guest-cart-thirdbox">
          <p className="guest-cart-billdetatilstext">Bill details</p>
          <div className="guest-cart-thirdbox-item-details">
            <span>Item total</span>
            <span>₹400</span>
          </div>
          <div className="guest-cart-thirdbox-item-details">
            <span>CGST</span>
            <span>₹95</span>
          </div>
          <div className="guest-cart-thirdbox-item-details">
            <span>SGST</span>
            <span>₹100</span>
          </div>
          <div className="guest-cart-thirdbox-item-details">
            <span>Delivery charge</span>
            <span>₹100</span>
          </div>
          <p className="guest-cart-topaytext">To Pay</p>
          <button className="guest-cart-addaddressandpaybutton">
            CONFIRM & PAY
          </button>
        </div>
      </div>

      {isPopupOpen && (
        <div className="guest-cart-popup-overlay">
          <div className="guest-cart-popup-content">
            <h2 className="guest-cart-popup-pre-head">
              Preparation Instructions
            </h2>
            <textarea
              className="guest-cart-popup-textarea"
              value={preparationInstructions}
              onChange={(e) => setPreparationInstructions(e.target.value)}
              placeholder="Enter your instructions here"
            ></textarea>
            <div className="guest-cart-popup-buttons">
              <button
                className="guest-cart-popup-button guest-cart-skip-button"
                onClick={handleClosePopup}
              >
                Skip
              </button>
              <button
                className="guest-cart-popup-button guest-cart-add-button"
                onClick={handleSaveInstructions}
              >
                Add
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default GuestCart;
