import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { motion, useMotionValue, useTransform, useSpring } from "framer-motion";
import Header from "../components/Web/header/Header";
import Footer from "../components/Web/Footer/Footer";
import firstimage from "../../src/images/Group 3.png";
import Slider from "react-slick";
import secondimage from "../../src/images/sweeny-home2-decor-img-02.png";
import thirdimage from "../../src/images/Group 57.png";

import rightarrow from "../../src/images/Group 44.svg";
import leftarrow from "../../src/images/Group 43.svg";
import sweetdealice from "../../src/images/icecream2-slider-pic7.png";
import milkwaveimg from "../../src/images/Group 57@2x.png";
import logo from "../../src/images/round-m.svg";
import bottomwave from "../../src/images/bottomwave.svg";
import lastdiviceimg from "../../src/images/h5_img-5@2x.png";
import coupon from "../../src/images/coupon.jpg";
import ourstoryspcl2 from "../../src/images/ice-oustory-2.png";
import ourstoryspcl3 from "../../src/images/ice-oustory-3.png";
import ourstoryspcl4 from "../../src/images/ice-oustory-4.png";
import ourstoryspcl5 from "../../src/images/ice-oustory-5.png";
import ourstoryspcl6 from "../../src/images/ice-oustory-6.png";
import doubleline from "../../src/images/double-line.svg";
import { isMobile } from "react-device-detect";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import "./homepage.css";
import MobHomePage from "../components/Mob/MobHomePage";
import axios from "axios";
const baseUrl = process.env.REACT_APP_BASEURL;

const HomePage = () => {
  const [data, setData] = useState([]);
  const [homepage, sethome] = useState([]);
  const [social, setsocial] = useState([]);

  const [productsLoading, setProductsLoading] = useState(true);
  const [coffeeText, setCoffeeText] = useState("COFFEE");
  const [icecreamText, setIcecreamText] = useState("icecream");
  const [gelantoText, setGelatoText] = useState("GELATO");
  const [categories, setCategories] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState("");
  const [products, setProducts] = useState([]);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [showPopup, setShowPopup] = useState(false);
  const [productsdata, setproductsdata] = useState([]);
  const [linkdata, setlinkdata] = useState([]);
  console.log(linkdata[0], "linkdata");

  const navigate = useNavigate();
  const itemsPerPage = 4;

  const handleNext = () => {
    if (currentIndex + itemsPerPage < products.length) {
      setCurrentIndex(currentIndex + itemsPerPage);
    }
  };
  const carouselsettings = {
    dots: data.length > 1,
    infinite: data.length > 1,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: data.length > 1,
    autoplaySpeed: 3000,
    arrows: data.length > 1,
  };
  const [isVisible, setIsVisible] = useState(false);

  function handleScroll() {
    const element = document.querySelector('.lastdiviceimg');
    if (element) {
      const rect = element.getBoundingClientRect();
      console.log(rect);
      setIsVisible(true);
    } else {
      console.warn('Element not found');
    }
  }

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);

    handleScroll();

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const handlePrev = () => {
    if (currentIndex - itemsPerPage >= 0) {
      setCurrentIndex(currentIndex - itemsPerPage);
    }
  };
  const handleCategoryClick = async (category, ...args) => {
    setSelectedCategory(category);
    setProductsLoading(true);
    try {
      const response = await axios.get(
        `${baseUrl}/api/products/all/category/${category}`
      );
      setProducts(response.data);
    } catch (error) {
      console.error("Error fetching products:", error);
    } finally {
      setProductsLoading(false);
    }
  };
  const handlePopupOpen = () => {
    setShowPopup(true);
  };

  const handlePopupClose = () => {
    setShowPopup(false);
  };

  const handleCoffeeClick = (value, newGelatoText, newIcecreamText) => {
    setCoffeeText(value);
    setGelatoText(newGelatoText);
    setIcecreamText(newIcecreamText);
  };
  const handleIcecreamClick = (
    newIcecreamText,
    newGelatoText,
    newCoffeeText
  ) => {
    console.log(newIcecreamText, newGelatoText, newCoffeeText);
    setIcecreamText(newIcecreamText);
    setGelatoText(newGelatoText);
    setCoffeeText(newCoffeeText);
  };

  const handlegelantoClick = (value, value1, value2) => {
    setGelatoText(value);
    setCoffeeText(value1);
    setIcecreamText(value2);
  };
  const handleProductClick = (productId) => {
    navigate(`/menu`);
  };
  useEffect(() => {
    const fetchSubCategories = async () => {
      try {
        const response = await axios.get(`${baseUrl}/api/alloncategories`);
        setCategories(Array.isArray(response.data) ? response.data : []);
        console.log(response?.data, "categories");
        if (response.data.length > 0) {
          setSelectedCategory(response.data[0]._id);
          const responses = await axios.get(
            `${baseUrl}/api/products/all/category/${response?.data[0]?._id}`
          );
          console.log(responses.data, "response.data");
          setProducts(responses.data);
        }
      } catch (error) {
        console.error("Error fetching categories:", error);
      } finally {
        setProductsLoading(false);
      }
    };
    fetchSubCategories();
  }, []);

  const fetchDetails = async () => {
    try {
      const response = await axios.get(`${baseUrl}/api/blog/oncarousals`);
      console.log(response.data, "kk");
      setData(response.data);
    } catch (error) {
      console.error("Network error:", error);
    }
  };
  const fetchhomedatas = async () => {
    try {
      const response = await axios.get(`${baseUrl}/api/homepage/allon`);
      console.log(response.data, "kk");
      sethome(response.data);
    } catch (error) {
      console.error("Network error:", error);
    }
  };

  useEffect(() => {
    fetchDetails();
    fetchhomedatas();
  }, []);
  useEffect(() => {
    const fetchproducts = async () => {
      try {
        const response = await axios.get(`${baseUrl}/api/user/allonhomepage`);
        const responses = await axios.get(`${baseUrl}/api/user/allonlinkpage`);
        setlinkdata(responses.data);

        console.log(response.data, "kk");
        setproductsdata(response.data);
      } catch (error) {
        console.error("Network error:", error);
      }
    };
    fetchproducts();
  }, []);
  return isMobile ? (
    <MobHomePage />
  ) : (
    <div>
      <Header />
      <div>
        <div className="ice-made-main-section">
          <div className="ice-made-img-section">
            <div className="image-container">
              <motion.img
                src={firstimage}
                className="firstimage"
                alt="First Ice Cream"
                animate={{
                  y: [0, -20, 0],
                  x: [-10, 10, -10],
                }}
                transition={{
                  duration: 4,
                  repeat: Infinity,
                  repeatType: "loop",
                  ease: "easeInOut",
                }}
              />
              {/* <motion.img
                src={secondimage}
                className="secimage"
                alt="Second Ice Cream"
                animate={{
                  scale: [1, 1.1, 1],
                }}
                transition={{
                  duration: 5,
                  repeat: Infinity,
                  repeatType: "loop",
                  ease: "easeInOut",
                }}
              /> */}
            </div>
            <div className="text-content">
              <p className="icecream-text-head">
                {homepage[1]?.title}
                <br />
                {/* <span className="icecream-text-passion">made with passion</span> */}
              </p>
              <p className="icecream-text-p">{homepage[1]?.subtitle}</p>
              <motion.div
                className="discover-button"
                whileHover={{ scale: 1.2 }}
                whileTap={{ scale: 2 }}
                onClick={() => {
                  navigate(homepage[1]?.link);
                }}
              >
                {homepage[1]?.buttontext}
              </motion.div>
            </div>
          </div>
          <img src={thirdimage} className="thirdimage" alt="Third Ice Cream" />
        </div>
        <img src={logo} className="logoimage" />
      </div>


    <div className="Home-carousel">
  <Slider {...carouselsettings} className="carousel-section">
    {data?.map((item, index) => (
      <div key={index} className="carousel-item">
        <img
          src={item?.image[0]}
          alt={`Slide ${index + 1}`}
          className="carousel-image"
          onClick={() => window.location.href = item?.link} 
        />
        <div className="carousel-text">
<p>{item?.text}</p>        </div>
      </div>
    ))}
  </Slider>
</div>


      <img src={bottomwave} className="topwave" alt="Top Wave" />

      <div className="bottombackground">
        <p className="bottombackground-head-text">Our Products</p>
        <p className="bottombackground-head-scoope">Explore Our Scoops!</p>
        <div className="products-container">
          {categories?.map((item, index) => (
            <p
              key={index}
              onClick={() => handleCategoryClick(item?._id)}
              style={{
                cursor: "pointer",
              }}
              className={
                selectedCategory === item?._id ? "icecreams" : "category"
              }
            >
              {item?.category}
            </p>
          ))}
        </div>

        <div>
        <div className="icecream-products-slider">
  <img
    src={leftarrow}
    className="sliderarrow right-arrow"
     alt="right arrow"
    onClick={handleNext}
  />
  <div className="icecream-products-bottombackground">
    {productsLoading ? (
      <div>Loading products...</div>
    ) : (
      products
        ?.slice(currentIndex, currentIndex + itemsPerPage)
        .map((item, index) => (
          <div
            className="icecream-singel-products-bottombackground"
            key={index}
            onClick={() => handleProductClick(item?._id)}
          >
            <motion.div
              className="image-container"
              whileHover={{ y: -40 }}
            >
              <img
                src={item?.image[0]}
                className="icreamslide"
                alt="ice cream"
              />
              <div>
                <p className="icreamslide-head-products">
                  {item?.productname}
                </p>
                <p className="icreamslide-sub-p">
                  {item?.subcategory?.subcategory}
                </p>
              </div>
            </motion.div>
          </div>
        ))
    )}
  </div>
  <img
     src={rightarrow}
    className="sliderarrow left-arrow"
    alt="left arrow"
    onClick={handlePrev}
  />
</div>

        </div>
      </div>
 
      <p className="ourstory-head">
        our <span className="Our-story-span">Story</span>
      </p>

      <div className="ourstory-container-main">
        <div className="ourstory-layout">
          <div className="ourstory-ice-img-section-left">
            <div className="ourstory-ice-img-section1">
              <p className="ourstory-ice-img-section1-p">
                {productsdata[0]?.description}{" "}
              </p>
              <img
                src={productsdata[0]?.image}
                className="ourstory-ice-img-section-image"
                alt="Our Story Special 1"
              />
            </div>
            <div className="ourstory-ice-img-section2">
              <p className="ourstory-ice-img-section2-p">
                {productsdata[1]?.description}{" "}
              </p>
              <img
                src={productsdata[1]?.image}
                alt="Our Story Special 2"
                className="ourstory-ice-img-section-image"
              />
            </div>
            <div className="ourstory-ice-img-section3">
              <p className="ourstory-ice-img-section3-p">
                {productsdata[2]?.description}{" "}
              </p>
              <img
                src={productsdata[2]?.image}
                className="ourstory-ice-img-section-image"
                alt="Our Story Special 3"
              />
            </div>
          </div>
          <div>
            <div className="container">
              <div className="bluediv">
                <div className="image-container-bluediv">
                  <motion.img
                    src={lastdiviceimg}
                    className="lastdiviceimg"
                    alt="Last Div Ice Image"
                    initial={{ opacity: 0, y: 50 }}
                    animate={{
                      opacity: isVisible ? 1 : 0,
                      y: isVisible ? 0 : 50,
                    }}
                    transition={{
                      duration: 1,
                      ease: "easeOut",
                    }}
                  />
                  <motion.div
                    className="discover-button-onice"
                    whileHover={{ scale: 1.2 }}
                    onClick={() => {
                      navigate(`/${linkdata[0]?.link}`);
                    }}
                    whileTap={{ scale: 2 }}
                  >
{homepage[3]?.buttontext}                  </motion.div>
                </div>
              </div>
              <div className="milkwave-container">
                <motion.img
                  src={milkwaveimg}
                  className="milkwaveimg"
                  alt="Milk Wave"
                  animate={{
                    y: [0, 15, 0],
                  }}
                  transition={{
                    repeat: Infinity,
                    duration: 5,
                    ease: "linear",
                  }}
                />
                <img
                  src={doubleline}
                  className="doubleline"
                  alt="Double Line"
                />
              </div>
            </div>
          </div>
          <div className="ourstory-ice-img-section-right">
            <div className="ourstory-ice-img-section4">
              <img
                src={productsdata[3]?.image}
                alt="Our Story Special 4"
                className="ourstory-ice-img-section-image"
              />
              <p className="ourstory-ice-img-section1-p">
                {productsdata[3]?.description}{" "}
              </p>
            </div>
            <div className="ourstory-ice-img-section5">
              <img
                src={productsdata[4]?.image}
                alt="Our Story Special 5"
                className="ourstory-ice-img-section-image"
              />
              <p className="ourstory-ice-img-section3-p">
                {productsdata[4]?.description}
              </p>
            </div>

            <div className="ourstory-ice-img-section6">
              <img
                src={productsdata[5]?.image}
                alt="Our Story Special 6"
                className="ourstory-ice-img-section-image"
              />
              <p className="ourstory-ice-img-section3-p">
                {productsdata[5]?.description}
              </p>
            </div>
          </div>
        </div>
      </div>
      {/* <div className="coupon-bottombackgroundcoupon">
  <div className="coupon-photo-container">
    <img src={coupon} alt="Image 1" className="coupon-photo"/>
    <img src={coupon} alt="Image 2" className="coupon-photo"/>
    <img src={coupon} alt="Image 3" className="coupon-photo"/>
    <img src={coupon} alt="Image 4" className="coupon-photo"/>
  </div>
</div> */}


      <div className="ourstory-main-section">
        <div className="sweet-deal-ourstory">
          <motion.img
            src={sweetdealice}
            whileHover={{ scale: 1.2 }}
            className="sweetdeal-ice"
            alt="Sweet Deal Ice Cream"
          />
          <div className="sweet-deal-content">
            <div className="sweet-deal-text-content">
              <h3 className="sweet-deal-head"> {homepage[2]?.title}</h3>
              <p className="sweet-deal-text">{homepage[2]?.subtitle}</p>
            </div>
            <motion.div
              className="discover-button"
              whileHover={{ scale: 1.2 }}
              whileTap={{ scale: 2 }}
              onClick={() => {
                navigate(homepage[2]?.link);
              }}
            >

{homepage[2]?.buttontext}            </motion.div>
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
};

export default HomePage;
